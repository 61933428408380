.list-menu--right {
  right: 0;
}

.list-menu--disclosure {
  position: absolute;
  min-width: 100%;
  width: 20rem;
  border: 1px solid rgba(var(--color-foreground), 0.2);
}

.list-menu--disclosure:focus {
  outline: none;
}

.list-menu__item--active {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.list-menu__item--active:hover {
  text-decoration-thickness: 0.2rem;
}

.list-menu--disclosure.localization-selector {
  max-height: 18rem;
  overflow: auto;
  width: 10rem;
  padding: 0.5rem;
}
